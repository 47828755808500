import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';

export function HoustonPane() {
  const components = [C1, C2, C3];
  const [state, setState] = useState(0);

  useEffect(()=> {
    const id = setInterval(() => setState((state + 1) % components.length), 10000);
      return ()=> clearInterval(id);
    },[components, setState, state]
  );

  const SelectedComponent = components[state];

  return <SelectedComponent/>;
}

function C1() {
  return <div style={{height: '100%'}}>
    <Iframe url="https://houston.ebanx.ninja/overview?path=CrossBorder/MerchantIntegration/Integrations"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  </div>;
}

function C2() {
  return <div style={{height: '100%'}}>
    <Iframe url="https://houston.ebanx.ninja/overview?path=CrossBorder/MerchantIntegration/PaymentPages"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  </div>;
}

function C3() {
  return <div style={{height: '100%'}}>
    <Iframe url="https://houston.ebanx.ninja/overview?path=CrossBorder/MerchantIntegration/Plugins"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  </div>;
}
