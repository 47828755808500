import React from 'react';
import Iframe from 'react-iframe';

export function IndexPane() {
  return <div style={{height: '100%'}}>
    <Iframe url="https://houston.ebanx.ninja/overview?path=CrossBorder/MerchantIntegration"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  </div>;
}
