import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getTrelloData } from './trello-data';

export function TrelloPane(){
  const [trelloData, setTrelloData] = useState(null);
  useEffect(()=>{
    (async ()=>{
      setTrelloData(await getTrelloData());

      setInterval(async ()=>{
        setTrelloData(await getTrelloData());
      }, 30000);
    })()
  }, []);

  if(!trelloData)
    return null;

  return(
    <TrelloBoardWrapper>
      {trelloData.map((element, index)=>{
        return <TrelloBox key={index} title={element['title']} cards={element['cards']} percentage={element['percentage']} treshold={element['treshold']} hours={element['hours']}/>
      })}
      </TrelloBoardWrapper>
  )
}

function TrelloBox({ title, cards, percentage, treshold, hours }){
  return(
    <TrelloBoxContainer percentage={percentage} treshold={treshold} cards={cards} title={title}>
      <p style={{fontSize: '3vh'}}>{title} ({cards})</p>
      <h1 style={{fontSize: '8vh', lineHeight: '10vh', fontWeight: 'bold'}}>{percentage}%</h1>
      <p style={{fontSize: '3vh'}}>{hours} hours</p>
    </TrelloBoxContainer>
  );
}

const TrelloBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.treshold(props) ? '#00a4c1' : '#9000FF'};
  color: #FFF;
  border: 5px solid  #414141;
  text-align: center;
  border-radius: 1em;
  flex-grow: 1;
  flex-basis: 0;
`;

const TrelloBoardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
