import React from 'react';
import './App.css';
import { MainPane } from './components/main-pane/main-pane';

function App() {
  return (
    <div className="App">
      <MainPane />
    </div>
  );
}

export default App;
