import React from 'react';
import styled from 'styled-components';

import { TrelloPane } from '../trello-pane';
import { IndexPane } from '../index-pane';
import { HoustonPane } from '../houston-pane';

export function MainPane() {
  return (
    <Root>
      <TopPane>
      </TopPane>

      <SidePane>
        <IndexPane />
      </SidePane>

      <DynamicPane>
        <HoustonPane />
      </DynamicPane>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-rows: 25% 75%;

  grid-template-areas:
   "top-pane top-pane"
   "side-pane dynamic-pane";

  background-color: #212121;
  height: 100vh;
`;

const TopPane = styled.div`
  grid-area: top-pane;
  background-color: #414141;
`;

const SidePane = styled.div`
  grid-area: side-pane;
  padding: 1em;
  background-color: #414141;
`;

const DynamicPane = styled.div`
  grid-area: dynamic-pane;
  padding: 1em;
  background-color: #414141;
`;
